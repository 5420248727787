const pager = {
	now: document.getElementById('sliderPager-now'),
	total: document.getElementById('sliderPager-total'),
};

let looped = false;

const kvSlider = new Swiper('#mainSlider', {
	loop: true,
	speed: 1000,
	effect: 'creative',
	autoplay: {
		delay: 5000,
	},
	navigation: {
		prevEl: '#sliderControl-prev',
		nextEl: '#sliderControl-next',
	},
	creativeEffect: {
		prev: {
			translate: [0, 0, 0],
		},
		next: {
			translate: [0, 1, 0],
		},
	},
	on: {
		init: function (swiper) {
			pager.total.innerHTML = swiper.slides.length;
		},
		slideChange: function (swiper) {
			if (!looped && swiper.activeIndex !== swiper.realIndex) {
				looped = true;
			}
			swiper.slides.forEach((el) => {
				const index = el.getAttribute('data-swiper-slide-index');
				if (index == swiper.realIndex) {
					el.classList.add('swiper-slide-show');
				} else {
					el.classList.remove('swiper-slide-show');
					if (looped) {
						return;
					}
					if (index != swiper.previousIndex) {
						el.style.zIndex = -1;
					}
				}
			});

			pager.now.classList.add('up');
			setTimeout(function () {
				pager.now.innerHTML = (swiper.realIndex + 1);
				pager.now.classList.remove('up');
				pager.now.classList.add('down');
			}, 500);
			setTimeout(function () {
				pager.now.classList.remove('down');
			}, 550);
		},
	},
	// pagination: {
	// 	el: '#sliderPager',
	// 	type: 'fraction',
	// },
});

const modelSlider = new Swiper('#modelSlider', {
	// loop: true,
	speed: 1000,
	slidesPerView: 'auto',
	// width: 245,
	// spaceBetween: 13,
	navigation: {
		prevEl: '#modelSliderControl-prev',
		nextEl: '#modelSliderControl-next',
	},
	// breakpoints: {
	// 	480: {
	// 		slidesPerView: 2,
	// 		width: 450,
	// 		spaceBetween: 20,
	// 	},
	// }
});
